/*Inter*/
@font-face {
    font-family: 'Inter-Regular';
    src: url('./fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('./fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('./fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url('./fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Black';
    src: url('./fonts/Inter/Inter-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*Raleway*/
@font-face {
    font-family: 'Raleway-Regular';
    src: url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-Medium';
    src: url('./fonts/Raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-SemiBold';
    src: url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-Bold';
    src: url('./fonts/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-ExtraBold';
    src: url('./fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-Black';
    src: url('./fonts/Raleway/Raleway-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}