/* Provide sufficient contrast against white background */
body {
    background: #202020;
    color: #FFFFFF;
    user-select:none;
}

a {
    color: #FFFFFF;
    text-decoration:none;
    cursor:pointer;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
