.ldvHomeContainer {
    width: 94.444vw;
}

.ldvHomeTitle {
    margin-top: 3.056vw;
    width: 72.111vw;
    font-family: 'Raleway-ExtraBold';
    font-size: 4.444vw;
    text-align: center;
    line-height: 5.222vw;
}

.ldvPointAContainer {
    margin-top: 5.667vw;
    background-image: url(/images/bg-pointA.svg);
    background-size: 100% 115.167vw;
    background-repeat: no-repeat, repeat;
    background-position: bottom;
}

.limgStartPic {
    width: 88.889vw;
    height: 46.667vw;
}

.ldvPointA {
    position: relative;
    background-image: url(/images/bg-pointA-content.svg);
    background-size: cover;
    margin-top: 11.833vw;
    width: 72.833vw;
    height: 33.389vw;
    border-radius: 2.778vw;
    padding: 3.889vw 4.222vw 6.056vw 4.222vw;
}

    .ldvPointA::after {
        background-image: url(/images/arrow.svg);
        background-size: 7.389vw 11.111vw;
        position: absolute;
        right: calc(50% - 7.389vw/2);
        bottom: -7vw;
        width: 7.389vw;
        height: 11.111vw;
        content: '';
    }

.ldvPointATextContainer {
    width: 42.667vw;
    justify-content: space-between;
}

.ldvPointATitle {
    font-family: 'Raleway-Bold';
    font-size: 4.444vw;
    line-height: 5.222vw;
}

.ldvPointAText {
    font-family: 'Inter-Regular';
    font-size: 1.667vw;
}

.ldvPointAImg {
    margin-left: 1.833vw;
}

.limgPointA {
    width: 18.167vw;
    height: 18.167vw;
}

.ldvPointADescription {
    margin-top: 12.778vw;
    width: 65.722vw;
    font-family: 'Raleway-Bold';
    font-size: 2.778vw;
    text-align: center;
    margin-bottom: 14.944vw;
    line-height: 4.167vw;
}

.ldvMiddle {
    position: relative;
    margin-top: 15.389vw;
    height: 54.722vw;
    background-color: #414141;
    border-radius: 2.778vw;
}

    .ldvMiddle::before {
        background-color: #3C3B3B;
        position: absolute;
        right: 0;
        left: 0;
        bottom: -2.778vw;
        height: 5.778vw;
        border-bottom-left-radius: 2.778vw;
        border-bottom-right-radius: 2.778vw;
        content: '';
        z-index: -1;
    }

.ldvMiddleContent {
    position: relative;
    width: 72.833vw;
    height: 30.611vw;
    border-radius: 2.778vw;
    font-family: 'Raleway-Bold';
    font-size: 4.444vw;
    line-height: 5.222vw;
    padding-bottom: 2.778vw;
    background-image: url(/images/bg-middle-content.svg);
    background-size: cover;
    background-position: bottom;
}
    .ldvMiddleContent::after {
        background-image: url(/images/arrow.svg);
        background-size: 7.389vw 11.111vw;
        position: absolute;
        right: calc(50% - 7.389vw/2);
        bottom: -6vw;
        width: 7.389vw;
        height: 11.111vw;
        content: '';
    }

.ldvExperience {
    margin-top: 7.278vw;
    height: 33.167vw;
    background-image: url(/images/pic01.svg);
    background-size: 45.444vw 27.778vw;
    background-position: center right;
    background-repeat: no-repeat, repeat;
}

.ldvSectionLeft {
    width: 76.167vw;
    justify-content:flex-start;
}

.ldvSectionRight {
    width: 76.167vw;
    justify-content: flex-end;
}

.ldvSectionContent {
    justify-content: space-between;
    width: 44.444vw;
}

.ldvSectionTitle {
    font-family: 'Raleway-ExtraBold';
    font-size: 4.444vw;
    line-height: 5.222vw;
}

.ldvSectionText {
    font-family: 'Inter-Regular';
    font-size: 1.611vw;
    line-height: 2.389vw;
}

.ldvInfluence {
    margin-top: 13.722vw;
    height: 33.611vw;
    background-image: url(/images/pic02.svg);
    background-size: 42.556vw 27.778vw;
    background-position: center left;
    background-repeat: no-repeat, repeat;
}

.ldvEfficiency {
    margin-top: 13.167vw;
    height: 33.611vw;
    background-image: url(/images/pic03.svg);
    background-size: 42.611vw 27.778vw;
    background-position: center right;
    background-repeat: no-repeat, repeat;
}

.ldvUse {
    margin-top: 13.444vw;
    height: 31.111vw;
    background-image: url(/images/pic04.svg);
    background-size: 40.667vw 27.778vw;
    background-position: center left;
    background-repeat: no-repeat, repeat;
}

.ldvSemantic {
    margin-top: 16.278vw;
    height: 28.611vw;
    background-image: url(/images/pic05.svg);
    background-size: 44.111vw 27.778vw;
    background-position: center right;
    background-repeat: no-repeat, repeat;
}

.ldvLogistic {
    margin-top: 16vw;
    height: 36.111vw;
    background-image: url(/images/pic06.svg);
    background-size: 41.611vw 29.167vw;
    background-position: center left;
    background-repeat: no-repeat, repeat;
}

.ldvPointBContainer {
    position:relative;
    margin-top: 12.778vw;
    background-color: #00B14F;
    border-radius: 2.778vw;
}

    .ldvPointBContainer::before {
        background-color: #377c4e;
        position: absolute;
        right: 0;
        left: 0;
        bottom: -2.778vw;
        height: 5.778vw;
        border-bottom-left-radius: 2.778vw;
        border-bottom-right-radius: 2.778vw;
        content: '';
        z-index: -1;
    }

.ldvPointB {
    position: relative;
    background-image: url(/images/bg-pointB-content.svg);
    background-size: cover;
    margin-top: 14vw;
    width: 72.222vw;
    height: 48.722vw;
    border-radius: 2.778vw;
    padding: 5.556vw 4.778vw 11.944vw 4.778vw;
}

    .ldvPointB::after {
        background-image: url(/images/arrow.svg);
        background-size: 7.389vw 11.111vw;
        position: absolute;
        right: calc(50% - 7.389vw/2);
        bottom: -4.5vw;
        width: 7.389vw;
        height: 11.111vw;
        content: '';
    }

.ldvPointBTextContainer {
    width: 42.667vw;
    justify-content: space-between;
}

.ldvPointBTitle {
    font-family: 'Raleway-Bold';
    font-size: 4.444vw;
    line-height: 5.222vw;
}

.ldvPointBText {
    font-family: 'Inter-Regular';
    font-size: 1.667vw;
}

.ldvPointBImg {
    margin-left: 1.833vw;
}

.limgPointB {
    width: 18.167vw;
    height: 18.167vw;
}

.ldvPointBDescription {
    margin-top: 11.944vw;
    width: 61.278vw;
    height: 23.222vw;
    margin-bottom: 8.833vw;
    justify-content: space-between;
}

.ldvPointBDescriptionTitle {
    font-family: 'Raleway-Bold';
    font-size: 5.556vw;
    line-height: 6.500vw;
}

.ldvPointBDescriptionText {
    width: 38.833vw;
    font-family: 'Inter-Regular';
    font-size: 1.667vw;
    text-align: center;
    line-height: 2.500vw;
}

.ldvPointBDescriptionBtn {
    width: 17.778vw;
    height: 4.944vw;
    background-color: #EE6352;
    border-radius: 2.778vw;
    font-family: 'Raleway-Medium';
    font-size: 1.667vw;
    line-height: 2.167vw;
    cursor:pointer;
}

.ldvFooter {
    margin-top: 2.778vw;
    height: 20.278vw;
    font-family: 'Inter-Regular';
    font-size: 1.667vw;
}