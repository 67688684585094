.ldvNavMenuContainer {
    width: 90vw;
    padding: 2.722vw 0;
}

.ldvNavLogo {
    width: 11.167vw;
    height: 3.111vw;
}

.ldvNavItemsContainer {
    width: 59.056vw;
    padding: 0 1.556vw;
}

.ldvNavItem {
    font-family: 'Raleway-Medium';
    font-size: 1.111vw;
    margin-left: 2.278vw;
    cursor:pointer;
}

.ldvLangContainer {
    width: 4.444vw;
    height: 2.389vw;
    cursor:pointer;
}

.ldvNavLangImg {
    width: 1.111vw;
    height: 1.111vw;
}

.ldvNavLangTitle, .ldvNavLoginTitle {
    font-family: 'Raleway-Medium';
    font-size: 1.111vw;
    margin-left: 0.500vw;
}

.ldvLoginContainer {
    width: 5.889vw;
    height: 1.944vw;
    margin-right: 0.611vw;
    cursor: pointer;
}

.ldvNavLoginImg {
    width: 1.111vw;
    height: 1.056vw;
}

.ldvRegBtn {
    font-family: 'Raleway-Medium';
    font-size: 1.111vw;
    width: 9.444vw;
    height: 2.833vw;
    background-color: #00B14F;
    border-radius: 2.167vw;
    cursor:pointer;
}